import { authGuard } from '@auth0/auth0-vue';
import { RaasUserType } from '@condo/domain';
import type { NavigationGuard, RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import { fetchRenovationProjectByCondoUuid, fetchRenovationProjectByUuid } from './api';
import { isFeatureEnabled } from './lib/feature';
import { auth0Client } from './plugins/auth0-client';
import { useRenovationProjectStore, useUserStore } from './store';

const isAdminOrEmployee: NavigationGuard = async () => {
    const store = useUserStore();
    await store.getUserIfStale();

    if (![RaasUserType.Admin, RaasUserType.Employee].includes(store.user?.type as RaasUserType)) {
        return { name: 'Unauthorized' };
    }
};

const clearRenovationProjectStore = async () => {
    const store = useRenovationProjectStore();
    store.clear();
};

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        component: () => import('@/pages/LandingLayout.vue'),
        name: 'LandingLayout',
        children: [
            {
                path: '',
                component: () => import('@/pages/Landing.vue'),
                name: 'Landing',
            },
            {
                path: 'impressum',
                component: () => import('@/pages/Impressum.vue'),
                name: 'Impressum',
            },
            {
                path: 'datenschutz',
                component: () => import('@/pages/Datenschutz.vue'),
                name: 'Datenschutz',
            },
        ],
    },
    {
        path: '/admin',
        component: () => import('@/pages/AdminLayout.vue'),
        name: 'Admin',
        children: [
            {
                path: '',
                component: () => import('@/pages/AdminLogin.vue'),
                name: 'AdminLogin',
            },
            {
                path: 'users',
                component: () => import('@/pages/UserList.vue'),
                name: 'UserList',
                beforeEnter: [authGuard, isAdminOrEmployee, clearRenovationProjectStore],
            },
            {
                path: 'settings',
                component: () => import('@/pages/ConfigSettings.vue'),
                name: 'ConfigSettings',
                beforeEnter: [authGuard, isAdminOrEmployee, clearRenovationProjectStore],
            },
            {
                path: 'renovation-projects',
                component: () => import('@/pages/RenovationProjectList.vue'),
                name: 'RenovationProjectList',
                beforeEnter: [authGuard, isAdminOrEmployee, clearRenovationProjectStore],
            },
            {
                path: 'cost-calculator',
                component: () => import('@/pages/CostCalculator.vue'),
                name: 'CostCalculator',
                beforeEnter: [authGuard, isAdminOrEmployee, clearRenovationProjectStore],
            },
            {
                path: 'renovation-projects/uuid/:uuid',
                component: () => import('@/pages/NotFound.vue'),
                name: 'RenovationProjectUuid',
                async beforeEnter(to) {
                    try {
                        const { renovationProjectId } = await fetchRenovationProjectByUuid(to.params.uuid as string);
                        return { name: 'RenovationProjectDetails', params: { renovationProjectId } };
                    } catch (err) {
                        return { name: '404' };
                    }
                },
            },
            {
                path: 'renovation-projects/condo-uuid/:condoUuid',
                component: () => import('@/pages/NotFound.vue'),
                name: 'RenovationProjectCondoUuid',
                async beforeEnter(to) {
                    try {
                        const { renovationProjectId } = await fetchRenovationProjectByCondoUuid(to.params.condoUuid as string);
                        return { name: 'RenovationProjectDetails', params: { renovationProjectId } };
                    } catch (err) {
                        return { name: '404' };
                    }
                },
            },
            {
                path: 'renovation-projects/:renovationProjectId',
                component: () => import('@/pages/RenovationProject.vue'),
                name: 'RenovationProject',
                beforeEnter: [
                    authGuard,
                    isAdminOrEmployee,
                    async ({ params }) => {
                        const store = useRenovationProjectStore();
                        store.clear();
                        await store.getOneById(+params.renovationProjectId);
                    },
                ],
                props: true,
                children: [
                    {
                        path: 'details',
                        component: () => import('@/pages/RenovationProjectDetails.vue'),
                        name: 'RenovationProjectDetails',
                        props: true,
                    },
                    {
                        path: 'checklist',
                        component: () => import('@/pages/RenovationProjectChecklist.vue'),
                        name: 'RenovationProjectChecklist',
                        props: true,
                    },
                    {
                        path: 'construction-cases',
                        component: () => import('@/pages/ConstructionCaseList.vue'),
                        name: 'ConstructionCases',
                        props: true,
                    },
                    {
                        path: 'inspections',
                        component: () => import('@/pages/RenovationInspectionList.vue'),
                        name: 'RenovationInspections',
                        props: true,
                    },
                    {
                        path: 'construction-plans',
                        component: () => import('@/pages/RenovationConstructionPlanList.vue'),
                        name: 'RenovationConstructionPlans',
                        props: true,
                    },
                    {
                        path: 'construction-cases/:constructionCaseId',
                        component: () => import('@/pages/ConstructionCaseDetail.vue'),
                        name: 'ConstructionCaseDetail',
                        props: true,
                    },
                    {
                        path: 'predictions',
                        component: () => import('@/pages/PredictionList.vue'),
                        name: 'PredictionList',
                        props: true,
                    },
                    {
                        path: 'documents',
                        component: () => import('@/pages/DocumentList.vue'),
                        name: 'DocumentList',
                        props: true,
                    },
                    {
                        path: 'media',
                        component: () => import('@/pages/MediaList.vue'),
                        name: 'MediaList',
                        props: true,
                    },
                    {
                        path: 'state-transition-list',
                        component: () => import('@/pages/RenovationProjectStateTransitionList.vue'),
                        name: 'RenovationProjectStateTransitionList',
                        props: true,
                    },
                ],
            },
        ],
    },
    {
        path: '/application/:step?',
        component: () => import('@/pages/ApplicationForm.vue'),
        name: 'ApplicationForm',
        beforeEnter: [() => (isFeatureEnabled('FEATURES_APPLICATION_FORM') ? true : { name: 'Unauthorized' })],
    },
    {
        path: '/application/results/:uuid/:step?',
        component: () => import('@/pages/ApplicationFormResults.vue'),
        name: 'ApplicationFormResults',
        props: true,
        beforeEnter: [() => (isFeatureEnabled('FEATURES_APPLICATION_FORM') ? true : { name: 'Unauthorized' })],
    },
    {
        path: '/contact',
        component: () => import('@/pages/Contact.vue'),
        name: 'ContactForm',
    },
    {
        path: '/contact/success',
        component: () => import('@/pages/ContactFormSuccess.vue'),
        name: 'ContactFormSuccess',
    },
    {
        path: '/calculator',
        component: () => import('@/pages/ExternalCostCalculator.vue'),
        name: 'ExternalCostCalculator',
    },
    {
        path: '/verification',
        component: () => import('@/pages/Verification.vue'),
        name: 'Verification',
    },
    {
        path: '/unauthorized',
        component: () => import('@/pages/Unauthorized.vue'),
        name: 'Unauthorized',
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/pages/NotFound.vue'),
        name: '404',
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
            };
        }

        return { top: 0 };
    },
});

router.beforeEach(async () => {
    if (auth0Client.isAuthenticated.value) {
        const store = useUserStore();
        await store.getUserIfStale();
    }

    return true;
});

export default router;
