import type { IRaasBuildConfig } from '@/types';
import { defineStore } from 'pinia';

export const useConfigStore = defineStore('config', () => {
    const config = ref<IRaasBuildConfig | null>(null);

    async function setConfig(configObj: IRaasBuildConfig) {
        config.value = configObj;
    }

    return { config: computed(() => config.value), setConfig };
});
