import { fetchRenovationProject } from '@/api';
import type { IRenovationProject } from '@condo/domain';
import { defineStore } from 'pinia';

export const useRenovationProjectStore = defineStore('renovationProject', () => {
    const renovationProject = ref<IRenovationProject | null>(null);

    async function getOneById(renovationProjectId: number) {
        const project = await fetchRenovationProject(renovationProjectId);
        renovationProject.value = project;
    }

    async function refetch() {
        if (renovationProject?.value?.renovationProjectId) {
            const project = await fetchRenovationProject(renovationProject.value.renovationProjectId);
            renovationProject.value = project;
        }
    }

    function clear() {
        renovationProject.value = null;
    }

    return { renovationProject: computed(() => renovationProject.value), getOneById, refetch, clear };
});
