import { initRaasApi } from '@/plugins/api-client';
import type { IRaasBuildConfig } from '@/types';
import { createAuth0 } from '@auth0/auth0-vue';
import '@condo/ui/build/style.css';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import { createGtm, useGtm } from '@gtm-support/vue-gtm';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { useCookies } from 'vue3-cookies';
import './css/tailwind.css';
import { useEnv } from './hooks/config.hooks';
import { exposeAuth0 } from './plugins/auth0-client';
import router from './router';
import { useConfigStore, useUserStore } from './store';

/*ICONS: https://www.xicons.org */
const initApp = (config: IRaasBuildConfig) => {
    const App = defineAsyncComponent(() => import('./App.vue'));
    const app = createApp(App);
    const pinia = createPinia();

    app.use(pinia);
    app.use(router);

    app.use(
        createGtm({
            id: config.GTM_ID,
            defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
            compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
            //@ts-ignore
            enabled: window.Cookiebot?.consent?.statistics ?? false, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
            debug: useEnv(config) === 'prod', // Whether or not display console logs debugs (optional)
            loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
            vueRouter: router as any, // Pass the router instance to automatically sync with router (optional)
            vueRouterAdditionalEventData: () => {
                const userStore = useUserStore();
                const { cookies } = useCookies();
                const cid = cookies.get('_ga').replace('GA1.1.', '');

                return { user_id: userStore.user?.email ?? cid };
            },
            ignoredViews: [], // Don't trigger events for specified router names (optional)
            trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
        }),
    );

    window.addEventListener(
        'CookiebotOnAccept',
        () => {
            //@ts-ignore
            if (window.Cookiebot?.consent?.statistics) {
                const gtm = useGtm();
                gtm?.enable(true);
            }
        },
        false,
    );

    app.use(VueQueryPlugin, {
        queryClientConfig: {
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false,
                    retry: 0,
                },
            },
        },
    });

    app.use(
        createAuth0({
            domain: config.AUTH0_DOMAIN,
            clientId: config.AUTH0_CLIENT_ID,
            authorizationParams: {
                redirect_uri: `${window.location.origin}/admin/renovation-projects`,
                audience: config.AUTH0_AUDIENCE,
                scope: 'openid profile email',
            },
        }),
    )
        .use(exposeAuth0())
        .use(Toast);

    app.use(VueGoogleMaps, { load: { key: config.GOOGLE_MAPS_KEY, libraries: ['places'] } });

    initRaasApi(config.S_RAAS_API_URL);

    return app;
};

(async () => {
    const response = await fetch('/config');
    const configObj: IRaasBuildConfig = await response.json();

    const app = initApp(configObj);

    const configStore = useConfigStore();
    configStore.setConfig(configObj);

    const meta = document.createElement('meta');
    meta.name = 'naive-ui-style';
    document.head.appendChild(meta);

    app.mount(document.body);
})();
