import type { Auth0VueClient } from '@auth0/auth0-vue';

export let auth0Client: Auth0VueClient = null!;

export function exposeAuth0() {
    return {
        async install(app: any) {
            auth0Client = app.config.globalProperties['$auth0'];
        },
    };
}

/**
 * Request new or get cached JWT access token
 * @returns {Promise<string>}
 */
export async function getBearerToken(ignoreCache = false): Promise<string> {
    return auth0Client.getAccessTokenSilently({
        authorizationParams: {
            scope: 'openid profile email',
        },
        cacheMode: ignoreCache ? 'off' : 'on',
    });
}
