import { raasApi } from '@/plugins/api-client';
import type { IGetRaasUsersParams, IRaasUser } from '@condo/domain';

export type FetchUsersParams = IGetRaasUsersParams['query'];

export const getUser = async (): Promise<IRaasUser> => raasApi.get('/user').then(({ data }) => data);

export const createUser = async (user: Partial<IRaasUser>): Promise<IRaasUser> => raasApi.post('/users', user).then(({ data }) => data);

export const updateUser = async (userId: number, user: Partial<IRaasUser>): Promise<IRaasUser> =>
    raasApi.patch(`/users/${userId}`, user).then(({ data }) => data);

export const fetchUsers = async (params: FetchUsersParams): Promise<{ users: IRaasUser[]; count: number }> =>
    raasApi.get('/users', { params }).then(({ data }) => data);
