import RequestError from '@/components/toasts/RequestError.vue';
import { auth0Client, getBearerToken } from '@/plugins/auth0-client';
import axios from 'axios';
import type { AxiosInstance } from 'axios';
import qs from 'qs';
import { v4 as uuid } from 'uuid';
import { useToast } from 'vue-toastification';
import { TYPE } from 'vue-toastification/src/ts/constants';

const toast = useToast();

export let raasApi: AxiosInstance = null as any;

export const handleRequestError = async (error: any) => {
    switch (error?.response?.data?.statusCode) {
        case 401:
            await auth0Client.loginWithRedirect({
                authorizationParams: {
                    redirect_uri: `${window.location.origin}/admin/renovation-projects`,
                },
                appState: { target: '/admin/renovation-projects' },
            });
            break;
        // biome-ignore lint/suspicious/noFallthroughSwitchClause: <explanation>
        case 422: {
            if (error?.response?.data?.message) {
                toast(error.response.data.message, { timeout: 15000, type: TYPE.ERROR });
                break;
            }
            // fallthrough
        }
        // add more status codes if needed
        default:
            toast(RequestError, { timeout: 15000, type: TYPE.ERROR });
    }
};

export const extendAxiosClient = (baseURL: string): AxiosInstance => {
    const client = axios.create({
        baseURL,
        paramsSerializer: {
            serialize: params => qs.stringify(params, { arrayFormat: 'repeat' }),
        },
    });

    client.interceptors.request.use(async config => {
        config.headers['x-stream-id'] = uuid();

        try {
            const token = await getBearerToken();

            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            return config;
        } catch (_) {
            return config;
        }
    });

    client.interceptors.response.use(
        response => response,
        async error => {
            if (!error?.config?.skipErrorHandler) {
                await handleRequestError(error);
            }

            return Promise.reject(error);
        },
    );

    return client;
};

export const initRaasApi = (baseURL: string) => {
    raasApi = extendAxiosClient(baseURL);
};
