import { raasApi } from '@/plugins/api-client';
import type { IRaasConfig } from '@condo/domain';

export const fetchActiveConfig = async (): Promise<IRaasConfig> => {
    return raasApi.get(`/config`).then(({ data }) => data);
};

export const updateConfig = async (configId: number, configData: Partial<Omit<IRaasConfig, 'configId'>>): Promise<IRaasConfig> => {
    return raasApi.put(`/configs/${configId}`, configData).then(({ data }) => data);
};
