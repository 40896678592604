import { type CalculateConstructionCaseResult, type ICostCalculatorInputs, type IEstate, InteriorQuality } from '@condo/domain';
import { defineStore } from 'pinia';

export const useCalculatorStore = defineStore('calculator', () => {
    const costCalculatorInputs = ref<ICostCalculatorInputs>({
        hasStoreyHeightCosts: false,
        hasGasBoilerCosts: false,
        hasNewFloorCosts: false,
        hasAsbestosCosts: false,
        hasMaisonetteCosts: false,
        hasPrefabricatedCosts: false,
        hasRoofStoreyCosts: false,
        hasHeatingPipesCosts: false,
        historicDoors: 0,
        interiorQuality: InteriorQuality.Normal,
        size: undefined as unknown as number,
        numberOfRooms: 0,
        numberOfBathrooms: 1,
        hasGuestToilet: false,
        hasStorageRoom: false,
        hasKitchen: true,
        hasElectricalCosts: true,
    });

    const calculationValues = ref<CalculateConstructionCaseResult['values']>();

    const location = ref<Pick<IEstate['location'], 'city' | 'zipcode' | 'street' | 'houseNumber'>>({
        city: '',
        houseNumber: '',
        street: '',
        zipcode: '',
    });

    async function setCalculationValues(payload: CalculateConstructionCaseResult['values']) {
        calculationValues.value = payload;
    }

    async function setCostCalculatorInputs(payload: ICostCalculatorInputs) {
        costCalculatorInputs.value = payload;
    }

    async function setLocation(payload: Pick<IEstate['location'], 'city' | 'zipcode' | 'street' | 'houseNumber'>) {
        location.value = payload;
    }

    return {
        calculationValues: calculationValues,
        costCalculatorInputs: costCalculatorInputs,
        location: location,
        setCalculationValues,
        setCostCalculatorInputs,
        setLocation,
    };
});
