import { raasApi } from '@/plugins/api-client';
import {
    ConstructionCase,
    type IConstructionCase,
    type IConstructionPlan,
    type IGetRenovationProjectPredictionsParams,
    type IGetRenovationProjectsParams,
    type IProcessContactRequest,
    type IRaasLinkMediasReq,
    type IRenovationInspection,
    type IRenovationProject,
    type IRenovationProjectChecklist,
    type IRenovationProjectPrediction,
    type IRenovationProjectStateTransition,
    type RenovationProjectApplicationResults,
    RenovationProjectPrediction,
    type RenovationProjectStatus,
} from '@condo/domain';
import axios, { type AxiosRequestConfig } from 'axios';

export type FetchRenovationProjectsParams = IGetRenovationProjectsParams['query'] & IGetRenovationProjectsParams['relationOpts'];

export const fetchRenovationProjects = async (
    params?: IGetRenovationProjectsParams['query'] & IGetRenovationProjectsParams['relationOpts'],
): Promise<{ renovationProjects: IRenovationProject[]; count: number; statusCounts: { [status: string]: number } }> => {
    return raasApi.get(`/renovation-projects`, { params }).then(({ data }) => data);
};

export const fetchStreets = async (params?: { partialStreetName: string }): Promise<string[]> => {
    return raasApi.get(`/renovation-projects/streets`, { params }).then(({ data }) => data);
};

export const fetchRenovationProject = async (renovationProjectId: number): Promise<IRenovationProject> => {
    return raasApi.get(`/renovation-projects/${renovationProjectId}`).then(({ data }) => data);
};

export const fetchRenovationProjectByCondoUuid = async (condoUuid: string): Promise<IRenovationProject> => {
    return raasApi.get(`/renovation-projects/condo-uuid/${condoUuid}`).then(({ data }) => data);
};

export const fetchRenovationProjectByUuid = async (uuid: string): Promise<IRenovationProject> => {
    return raasApi.get(`/renovation-projects/uuid/${uuid}`).then(({ data }) => data);
};

export const createRenovationProject = async (
    renovationProject: Partial<IRenovationProject> & {
        mediaFiles: IRaasLinkMediasReq['Body']['files'];
    },
    requestOpts?: { skipErrorHandler?: boolean },
): Promise<IRenovationProject> => {
    return raasApi
        .post(`/renovation-projects`, renovationProject, { skipErrorHandler: requestOpts?.skipErrorHandler } as AxiosRequestConfig)
        .then(({ data }) => data);
};

export const updateRenovationProject = async (renovationProjectId: number, renovationProject: Partial<IRenovationProject>): Promise<IRenovationProject> => {
    return raasApi.put(`/renovation-projects/${renovationProjectId}`, renovationProject).then(({ data }) => data);
};

export const fetchRenovationProjectConstructionCases = async (renovationProjectId: number): Promise<ConstructionCase[]> => {
    return raasApi
        .get(`/renovation-projects/${renovationProjectId}/construction-cases`)
        .then(({ data }) => data.map((cc: IConstructionCase) => new ConstructionCase(cc)));
};

export const fetchRenovationProjectInspections = async (renovationProjectId: number): Promise<IRenovationInspection[]> => {
    return raasApi.get(`/renovation-projects/${renovationProjectId}/renovation-inspections`).then(({ data }) => data);
};

export const fetchRenovationProjectStateTransitions = async (renovationProjectId: number): Promise<IRenovationProjectStateTransition[]> => {
    return raasApi.get(`/renovation-projects/${renovationProjectId}/state-transitions`).then(({ data }) => data);
};

export const fetchRenovationProjectConstructionPlans = async (renovationProjectId: number): Promise<IConstructionPlan[]> => {
    return raasApi.get(`/renovation-projects/${renovationProjectId}/construction-plans`).then(({ data }) => data);
};

export const fetchRenovationProjectPredictions = async (
    renovationProjectId: number,
    params?: IGetRenovationProjectPredictionsParams['query'],
): Promise<{ renovationProjectPredictions: RenovationProjectPrediction[]; count: number }> => {
    return raasApi.get(`/renovation-projects/${renovationProjectId}/predictions`, { params }).then(({ data }) => ({
        renovationProjectPredictions: data.renovationProjectPredictions.map((p: IRenovationProjectPrediction) => new RenovationProjectPrediction(p)),
        count: data.count,
    }));
};

export const requestNewRenovationProjectPredictions = async (renovationProjectId: number): Promise<RenovationProjectPrediction[]> => {
    return raasApi
        .post(`/renovation-projects/${renovationProjectId}/predictions`)
        .then(({ data }) => data.map((p: IRenovationProjectPrediction) => new RenovationProjectPrediction(p)));
};

export const setRenovationProjectActiveConstructionCase = async (renovationProjectId: number, constructionCaseId: number): Promise<IRenovationProject> => {
    return raasApi.post(`/renovation-projects/${renovationProjectId}/construction-cases/active/${constructionCaseId}`).then(({ data }) => data);
};

export const fetchRenovationProjectTransitions = async (status: RenovationProjectStatus): Promise<{ event: string; target: RenovationProjectStatus }[]> => {
    return raasApi.get(`/renovation-projects/machine/transitions`, { params: { status } }).then(({ data }) => data);
};

export const fetchOrCreateRenovationProjectChecklist = async (renovationProjectId: number): Promise<IRenovationProjectChecklist> => {
    try {
        const { data } = await raasApi.get(`/renovation-projects/${renovationProjectId}/checklist`, {
            skipErrorHandler: true,
        } as AxiosRequestConfig);
        return data;
    } catch (err: unknown) {
        if (axios.isAxiosError(err) && err.response?.status === 404) {
            const { data } = await raasApi.post(`/renovation-projects/${renovationProjectId}/checklist`);
            return data;
        }
        throw err;
    }
};

export const updateApplicationRenovationChecklist = async (
    renovationProjectId: number,
    renovationProjectChecklist: Partial<IRenovationProjectChecklist>,
): Promise<IRenovationProject> => {
    return raasApi.patch(`/renovation-projects/${renovationProjectId}/checklist`, renovationProjectChecklist).then(({ data }) => data);
};

export const triggerRenovationProjectEvent = async (renovationProjectId: number, event: string): Promise<IRenovationProject> => {
    return raasApi.post(`/renovation-projects/${renovationProjectId}/event`, { event }).then(({ data }) => data);
};

export const checkRenovationProjectExists = async (uuid: string, requestOpts?: { skipErrorHandler?: boolean }): Promise<void> => {
    return raasApi.head(`/renovation-projects/${uuid}`, { skipErrorHandler: requestOpts?.skipErrorHandler } as AxiosRequestConfig);
};

export const fetchRenovationProjectApplicationResult = async (uuid: string): Promise<RenovationProjectApplicationResults> => {
    return raasApi.get(`/renovation-projects/${uuid}/application-results`).then(({ data }) => data);
};

export const generateProfitabilityDocument = async (uuid: string): Promise<string> => {
    return raasApi.post(`/renovation-projects/${uuid}/profitability-document`).then(({ data }) => data);
};

export const updateApplicationRenovationProject = async (
    uuid: string,
    renovationProject: Partial<IRenovationProject>,
    requestOpts?: { skipErrorHandler?: boolean },
): Promise<RenovationProjectApplicationResults> => {
    return raasApi
        .put(`/renovation-projects/${uuid}/application`, renovationProject, {
            skipErrorHandler: requestOpts?.skipErrorHandler,
        } as AxiosRequestConfig)
        .then(({ data }) => data);
};

export const processContactRequest = async (
    uuid: string,
    contactRequest: IProcessContactRequest['Body'],
    requestOpts?: { skipErrorHandler?: boolean },
): Promise<void> => {
    return raasApi
        .post(`/renovation-projects/${uuid}/contact-request`, contactRequest, {
            skipErrorHandler: requestOpts?.skipErrorHandler,
        } as AxiosRequestConfig)
        .then(({ data }) => data);
};
