import { fetchActiveConfig, updateConfig } from '@/api';
import { useConfigStore } from '@/store';
import type { IRaasBuildConfig } from '@/types';
import type { IRaasConfig } from '@condo/domain';
import { useMutation, useQuery } from '@tanstack/vue-query';
import { omit } from 'lodash-es';

export const useEnv = (config?: IRaasBuildConfig) => {
    if (config) {
        return config.ENVIRONMENT_NAME || 'local';
    }

    const configStore = useConfigStore();

    return configStore?.config?.ENVIRONMENT_NAME || 'local';
};

export const useActiveConfig = () => {
    const config = ref<IRaasConfig>();

    const query = useQuery(['config'], async () => fetchActiveConfig(), {
        onSuccess: data => {
            config.value = data;
        },
    });

    const { mutateAsync: updateConfigMutation } = useMutation(
        async (config: Partial<IRaasConfig>) => updateConfig(query.data.value!.configId, omit(config, ['configId', 'active'])),
        {
            onSuccess: () => {
                query.refetch();
            },
        },
    );

    return { ...query, updateConfig: updateConfigMutation, mutableData: config };
};
