import { getUser as fetchUser } from '@/api';
import type { IRaasUser } from '@condo/domain';
import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', () => {
    const user = ref<IRaasUser | null>(null);

    async function getUser() {
        const userData = await fetchUser();
        user.value = userData;
        return user.value;
    }

    async function getUserIfStale() {
        if (!user.value) {
            return getUser();
        }
        return user.value;
    }

    function logout() {
        user.value = null;
    }

    return { user, getUser, getUserIfStale, logout };
});
