export const tel = {
    visible: '040 278 841 11',
    href: 'tel:04027884111',
};

export const email = {
    service: 'service@reneo.green',
    dataprotection: 'datenschutz@reneo.green',
    info: 'info@reneo.de',
    warranty: 'gewaehrleistung@reneo.de',
};
